import React from "react"
import Layout from "../components/layout"
import "../styles/terms.scss"
export default function Terms() {
  return (
    <Layout>
        <div className="terms">
            <h1>Cookie Policy</h1>
            <p className="date">Senast uppdaterad 2023-08-01</p>

            <h2>What are cookies</h2>
            <p>Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third­party to recognize you and make your next visit easier and the Service more useful to you. Cookies can be "persistent" or "session" cookies.</p>

            
            <h2>How Utekoll uses cookies</h2>
            <p>When you use and access the Service, we may place a number of cookies files in your web browser. We use cookies for the following purposes: to enable certain functions of the Service, to provide analyticsand to store your preferences. In the future we may use them to enable advertisement delivery, including behavioral advertising.</p>
            <p>We use both session and persistent cookies on the Service and we use different types of cookies to run the Service:</p>

            <h2>Essential cookies</h2>
            <p>We may use essential cookies to authenticate users and prevent fraudulent use of user accounts.</p>

            <h2>Third­party cookies</h2>
            <p>In addition to our own cookies, we may also use various third­parties cookies to report usage statistics of the Service, deliver advertisements on and through the Service, and so on.</p>

            <h2>What are your choices regarding cookies</h2>
            <p>If you'd like to delete cookies or instruct your web browser to delete or refuse cookies, please visit the help pages of your web browser. Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use all of the features we offer, you may not be able to store your preferences, and some of our pages might not display properly.</p>

            <h2>More information about cookies</h2>
            <p>You can learn more about cookies and the following third­party websites:</p>
            <ul>
                <li><a href="http://www.aboutcookies.org">About Cookies dot Org</a></li>
                <li><a href="http://www.networkadvertising.org/">Network Advertising Initiative</a></li>
            </ul>

            <h2>How to contact us</h2>
            <p>By email at info@iskoll.se</p>

        </div>
    </Layout>
  )
}